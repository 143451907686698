import Icon from '@elements/Icon';
import { Menu } from '@headlessui/react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const MenuLink = forwardRef((props, ref) => {
  const { active, itemData, close } = props;
  return (
    <Link
      ref={ref}
      className={`group flex w-full items-center rounded-md p-3 text-sm font-normal hover:bg-accent hover:text-white/90 ${
        active ? 'bg-accent text-primary-dark' : 'text-primary-dark'
      }`}
      href={itemData.href || '/'}
      onClick={close}
    >
      {itemData.name}
    </Link>
  );
});

MenuLink.displayName = 'MenuLink';

export function MenuItem(props) {
  const { itemData } = props;
  return (
    <Menu.Item>
      {({ active, close }) => <MenuLink active={active} itemData={itemData} close={close} />}
    </Menu.Item>
  );
}

export function IconMenuItem(props) {
  const { itemData } = props;
  return (
    <Menu.Item>
      {({ active, close }) => (
        <div
          className={`flex items-center gap-2 rounded-md ${
            active ? 'bg-accent text-white' : 'text-primary-dark'
          }`}
        >
          <Link
            onClick={close}
            className={`group w-full rounded-md p-3  text-sm ${
              active ? 'bg-accent text-white' : 'text-primary-dark'
            }`}
            href={itemData.href}
          >
            <div className="flex w-full gap-6">
              <Icon
                overrideClass="w-6"
                iconName={itemData?.icon}
                color={active ? 'text-white' : 'text-primary-dark'}
                size="lg"
              />

              <p>{itemData.name}</p>
            </div>
          </Link>
        </div>
      )}
    </Menu.Item>
  );
}

export const MenuItemType = ({ itemData }) =>
  'icon' in itemData ? <IconMenuItem itemData={itemData} /> : <MenuItem itemData={itemData} />;

MenuItemType.propTypes = {
  itemData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};

MenuLink.propTypes = {
  active: PropTypes.bool.isRequired,
  itemData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
  }).isRequired,
};

IconMenuItem.propTypes = {
  itemData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};
