import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import { deleteOrganizationRequest } from '@providers/organization';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DeleteOrganizationForm() {
  const { organizationId } = userStore();
  const [showConfirmation, setShowConfirmation] = useState(false);

  async function submitDeleteRequest() {
    const response = await deleteOrganizationRequest(organizationId);
    if (response && response.status === 204) {
      toast.success('Verzoek succesvol verzonden.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setShowConfirmation(false);
    } else {
      toast.error('Verzoek is niet verzonden.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <h2 className="font-primary text-3xl">Instellingen</h2>
          <p className="mb-8">Hier kun je de organisatie verwijderen.</p>
        </div>
      </div>
      <ul className="flex w-full flex-col gap-4 rounded-lg bg-white px-8 py-4 shadow-lg">
        <p className="pt-2">Weet je zeker dat je de organisatie definitief wilt verwijderen?</p>
        <p>
          Let op: deze actie is onomkeerbaar. Je verliest toegang tot al je licenties, aankopen,
          bestelgeschiedenis en facturen.
        </p>
        <div className="ml-auto py-2">
          <Button
            type="button"
            buttonType="danger"
            onClick={() => setShowConfirmation(true)}
            label="Verwijder organisatie"
            overrideClass="text-white/90"
          />
        </div>
      </ul>

      {showConfirmation && (
        <div className="fixed left-0 top-0 flex size-full items-center justify-center bg-gray-800/75">
          <div className="rounded-lg bg-white p-8">
            <p className="mb-4">Bevestig alsjeblieft dat je de organisatie wilt verwijderen:</p>
            <div className="flex justify-end gap-3">
              <Button
                type="button"
                buttonType="danger"
                onClick={submitDeleteRequest}
                label="Ja"
                overrideClass="text-white/90"
              />
              <Button
                type="button"
                buttonType="secondary"
                onClick={() => setShowConfirmation(false)}
                label="Nee"
              />
            </div>
          </div>
        </div>
      )}
      <ToastContainer theme="light" />
    </div>
  );
}
