/**
 * Builds the search term options part from passed in search term and the fields to apply to the search term to
 */
function buildSearchTermOptions(searchTerm, fields, match) {
  const fieldList = [];
  fields?.forEach((field) => {
    if (field?.boost > 0) {
      fieldList.push(`${field.field}^${field.boost}`);
    } else {
      fieldList.push(field.field);
    }
  });
  if (match && searchTerm?.length > 0) {
    return {
      match: {
        [match]: {
          query: searchTerm,
          operator: 'and',
        },
      },
    };
  }
  if (searchTerm?.length > 0) {
    return {
      multi_match: {
        query: searchTerm,
        fields: fieldList,
        operator: 'and',
        type: 'best_fields',
      },
    };
  } else {
    return { match_all: {} };
  }
}

/**
 * Builds the highlight options part from passed in fields
 */
function buildHighlightOptions(fields, index_type) {
  const fieldsObj = [];
  fields?.forEach((value) => {
    fieldsObj.push({ [value]: { pre_tags: ['<mark>'], post_tags: ['</mark>'] } });
  });
  return {
    highlight: {
      require_field_match: true,
      fragment_size: index_type === 'node' ? 25 : 500,
      fields: fieldsObj,
    },
  };
}

/**
 * Builds the suggest options part from passed in fields
 */
function buildSuggestOptions(suggestions, searchTerm) {
  const suggestObj = {};
  suggestions?.forEach((suggest) => {
    suggestObj[suggest.field] = {
      prefix: searchTerm,
      completion: {
        field: suggest.field,
        size: suggest.size,
        skip_duplicates: true,
      },
    };
  });

  return {
    suggest: {
      ...suggestObj,
    },
  };
}

/**
 * Builds the filter options part from passed in filters (which contain the field names and values)
 */
function buildFilterOptions(filters) {
  const filtersList = [];
  filters?.forEach((filter) => {
    filtersList.push({
      term: {
        [filter.field]: filter.value,
      },
    });
  });
  return { filter: filtersList };
}

/**
 * Builds the facet options part from passed in facets (which include the field names and size)
 */
function buildFacetOptions(facets) {
  const aggs = {};
  facets?.forEach((facet) => {
    aggs[facet.field] = {
      terms: {
        field: facet.field,
        size: facet.size,
      },
    };
  });
  return aggs;
}

/**
 * Builds the sort options part from passed in sorts (which include the field names and order to sort by)
 */
function buildSortOptions(sorts) {
  const sortsObj = {};
  sorts?.forEach((sort) => {
    sortsObj[sort.field] = {
      order: sort.order,
    };
  });
  return { sort: sortsObj };
}

/**
 * Builds the return field options part from passed in fields (string of fields to return)
 */
function buildReturnFieldOptions(fields) {
  return {
    fields: fields,
    source: false,
  };
}

/**
 * Builds the searchQuery options based on the filter and search term options
 */
function buildSearchQueryOptions(filterOptions, searchTermOptions) {
  return {
    bool: {
      ...filterOptions,
      must: {
        ...searchTermOptions,
      },
    },
  };
}

/**
 * Builds the pagination options part from passed in from and size values
 */
function buildPaginationOptions(from, size) {
  return {
    from: from,
    size: size,
  };
}

/**
 * Removes search terms from the searchConfig object if values are passed into the options array
 */
function removeSearchOptions(searchConfig, options) {
  options?.forEach((option) => {
    delete searchConfig[option];
  });
}

export {
  buildFacetOptions,
  buildFilterOptions,
  buildHighlightOptions,
  buildPaginationOptions,
  buildReturnFieldOptions,
  buildSearchQueryOptions,
  buildSearchTermOptions,
  buildSortOptions,
  buildSuggestOptions,
  removeSearchOptions,
};
