import Seats from 'feature/organization/Seats';
import 'react-toastify/dist/ReactToastify.css';
import OrganizationSubscriptionItem from './OrganizationSubscriptionItem';
import OrganizationSubscriptionSummary from './OrganizationSubscriptionSummary';

export default function OrganizationSubscriptionCard(organizationSubscription) {
  if (organizationSubscription.organizationType === 'ORGANIZATION') {
    let used = 0;

    if (organizationSubscription?.subscriptionMemberCount?.plus_active) {
      used += organizationSubscription?.subscriptionMemberCount?.plus_active;
    }

    if (organizationSubscription?.subscriptionMemberCount?.standard_active) {
      used += organizationSubscription?.subscriptionMemberCount?.standard_active;
    }

    return (
      <div className="rounded-md bg-white shadow-lg ">
        <OrganizationSubscriptionSummary {...organizationSubscription} />
        <div className="border-t"></div>
        <OrganizationSubscriptionItem {...organizationSubscription} />
        <div className="mb-4 border-t"></div>
        <Seats
          used={used}
          pending={organizationSubscription?.subscriptionMemberCount?.pending}
          total={organizationSubscription?.subscriptionMemberCount?.total_seats}
        />
      </div>
    );
  }

  return (
    <div className="rounded-md bg-white shadow-lg ">
      <OrganizationSubscriptionSummary {...organizationSubscription} />
      <div className="border-t"></div>
      <OrganizationSubscriptionItem {...organizationSubscription} />
      <div className="mb-4 border-t"></div>
      <Seats
        used={organizationSubscription?.subscriptionMemberCount?.active}
        pending={organizationSubscription?.subscriptionMemberCount?.pending}
        total={organizationSubscription?.subscriptionMemberCount?.total}
      />
    </div>
  );
}
