import * as Sentry from '@sentry/browser';
import cookieCutter from 'cookie-cutter';
import { clientApi } from './clientApi';

export async function addToCart(documentId) {
  try {
    await fetchCartSession();

    return await clientApi(
      '/ecommerce/cart',
      'POST',
      null,
      JSON.stringify({
        cart_session_id: cart_session_id,
        document_id: documentId,
        quantity: 1,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function deleteCartItem(documentId) {
  try {
    await fetchCartSession();

    return await clientApi(
      `/ecommerce/cart?cart_session_id=${cart_session_id}&document_id=${documentId}`,
      'DELETE',
      null,
      JSON.stringify({ cart_session_id: cart_session_id, document_id: documentId }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateCartItem(documentId, quantity) {
  try {
    await fetchCartSession();

    return await clientApi(
      '/ecommerce/cart',
      'PUT',
      null,
      JSON.stringify({
        cart_session_id: cart_session_id,
        document_id: documentId,
        quantity: quantity,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCart() {
  try {
    await fetchCartSession();

    if (cart_session_id == '') {
      return null;
    }

    return await clientApi(`/ecommerce/cart/${cart_session_id}`, 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCartFinal() {
  try {
    await fetchCartSession();

    if (cart_session_id == '') {
      return null;
    }

    return await clientApi(`/ecommerce/cart/final/${cart_session_id}`, 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCartSession() {
  cookieCutter.set('cart_session_id', '', { path: '/', expires: new Date(0) });

  try {
    const response = await clientApi('/session/cart', 'GET', null);
    cart_session_id = response.data.cart_session;
    cookieCutter.set('cart_session_id', cart_session_id, { path: '/' });
  } catch (error) {
    Sentry.captureException(error);
  }
}

let cart_session_id;

export async function fetchCartSession() {
  if (cart_session_id == null) {
    cart_session_id = cookieCutter.get('cart_session_id');
  }
  try {
    if (cart_session_id == null) {
      const response = await clientApi('/session/cart', 'GET', null);
      cart_session_id = response.data.cart_session;
      cookieCutter.set('cart_session_id', cart_session_id, { path: '/' });
    }
    return cart_session_id;
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function deleteCartSession() {
  cookieCutter.set('cart_session_id', '', { path: '/', expires: new Date(0) });
}

export async function createPhysicalOrder(body) {
  try {
    return await clientApi('/ecommerce/customer', 'POST', null, JSON.stringify(body));
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export async function resendPhysicalOrder(body) {
  try {
    return await clientApi('/ecommerce/customer', 'PUT', null, JSON.stringify(body));
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export async function payLaterOption(cart_session_id, payload) {
  try {
    return await clientApi(
      `/ecommerce/orders/physical/later_payment/${cart_session_id}`,
      'POST',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export async function payNowOption(cart_session_id, payload) {
  try {
    return await clientApi(
      `/ecommerce/orders/physical/direct_payment/${cart_session_id}`,
      'POST',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export async function getCartSummary() {
  try {
    return await clientApi(`/ecommerce/physical_products/additional_costs`, 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function setCartSession(cart_session_id) {
  cookieCutter.set('cart_session_id', cart_session_id, { path: '/' });
}
