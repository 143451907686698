import { Menu, Transition } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import PropTypes from 'prop-types';
import { PrimaryMenuButton, SecondaryMenuButton } from './MenuButton';
import { MenuItemType } from './MenuItem';

const Dropdown = ({ isLeft, isPrimary, label, items }) => {
  return (
    <div className="z-[10] text-right">
      <Menu as="div" className="inline-block h-full text-left">
        {({ open }) => (
          <>
            {isPrimary ? (
              <PrimaryMenuButton label={label} open={open} />
            ) : (
              <SecondaryMenuButton label={label} open={open} />
            )}
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              {/* If isLeft is true, the menu will be rendered with left-0, otherwise right-0 */}
              <div className="relative">
                <Menu.Items
                  className={classNames(
                    isLeft ? 'left-0' : 'right-0',
                    'absolute -top-2 mt-2 max-h-[28rem] w-64 origin-top-left divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  )}
                >
                  {items?.map((item) => (
                    <div key={item.id} className="p-1">
                      <MenuItemType itemData={item} />
                    </div>
                  ))}
                </Menu.Items>
              </div>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

Dropdown.propTypes = {
  isLeft: PropTypes.bool,
  isPrimary: PropTypes.bool,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};

Dropdown.defaultProps = {
  isLeft: false,
  isPrimary: false,
  label: '',
  items: [],
};

export default Dropdown;
