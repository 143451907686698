import Icon from '@elements/Icon';
import NextLink from '@elements/links/NextLink';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { getDate } from '@helpers/getDate';
import { getThumbnailUrl } from '@helpers/getThumbnailUrl';
import { performClientSearch } from '@providers/search';
import { buildSearchConfig } from '@search/helpers/searchConfig';
import { usePlausible } from 'next-plausible';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { baseStyle } from './searchBoxConstants';

let suggestedTerms = [];

export default function SearchBoxContext(props) {
  const router = useRouter();
  const plausibleEvent = usePlausible();
  const { register, handleSubmit } = useForm();
  const {
    handleSearch,
    config: { showSuggestions, redirect, form, input, button },
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState('');
  const debounceTimer = useRef(null);

  useEffect(() => {
    if (!router?.query?.query) {
      setQuery('');
    }
  }, [router?.query?.query]);

  async function getSearchSuggestions(query) {
    const body = buildSearchConfig({
      filters: [{ field: 'Status.keyword', value: 'Actueel' }],
      searchTerm: query,
      searchTermFields: [{ field: 'DocumentTitleSearchTyping' }],
      removeOptions: ['suggest', 'highlight', 'sort', 'aggs'],
      returnFields: ['Title', 'DocumentId', 'DocumentType', 'PublicationDate'],
      index_type: 'document',
    });

    const index_type = 'document';

    const response = await performClientSearch(body, index_type);

    if (response?.status === 200) {
      const suggestionOptions = Object?.values(response.data.hits.hits);
      suggestionOptions.length = 5;

      return suggestionOptions.map(({ fields }) => ({
        documentId: fields.DocumentId,
        title: fields.Title,
        documentType: fields.DocumentType,
        thumbnailUrl: getThumbnailUrl(fields.DocumentId),
        publicationDate: getDate(fields.PublicationDate),
      }));
    }
  }

  async function getSearchTermSuggestions(query) {
    const body = {
      size: 0,
      aggregations: {
        aggregations: {
          filter: {
            prefix: {
              DocumentTitleAutocomplete: query,
            },
          },
          aggregations: {
            aggregations: {
              terms: {
                field: 'DocumentTitleAutocomplete',
                include: query + '.*',
              },
            },
          },
        },
      },
    };
    const index_type = 'document';
    const response = await performClientSearch(body, index_type);

    if (response?.status === 200) {
      const termSuggestions = response?.data?.aggregations?.aggregations?.aggregations?.buckets;
      termSuggestions.length = 5;
      return termSuggestions.map((item) => item.key);
    }
  }

  async function handleChange(event) {
    const value = event.target.value;
    setQuery(value);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(async () => {
      if (value?.length > 1) {
        const suggestions = await getSearchSuggestions(value);
        if (suggestions) {
          suggestedTerms = await getSearchTermSuggestions(value);
          setSuggestions(suggestions);
          setShowModal(true);
        } else {
          setShowModal(false);
        }
      } else {
        setShowModal(false);
      }
    }, 500);
  }

  function handleModal(event) {
    if (event.key === 'Enter') {
      setShowModal(false);
    }
  }

  function renderSuggestions() {
    return (
      <Transition
        className="relative"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        show={showModal}
      >
        <Menu.Items
          className={classNames(
            'absolute top-1 mt-2 w-full overflow-auto bg-white font-secondary text-primary-dark shadow-lg focus:outline-none',
          )}
        >
          <div className="flex flex-col gap-4 p-4">
            {suggestedTerms?.length > 0 && <div className="pt-2">Suggesties</div>}
            {suggestedTerms?.map((suggestion, index) => (
              <Menu.Item key={index}>
                <NextLink
                  linkType="secondary"
                  className="text-sm"
                  href={`/search?query=${suggestion}`}
                >
                  {suggestion}
                </NextLink>
              </Menu.Item>
            ))}

            {/* Suggested documents */}
            {suggestions?.length > 0 && <div className="pt-2">Documenten</div>}
            {suggestions?.map((suggestion, index) => {
              return (
                <button className="text-left" key={index}>
                  <Menu.Item key={index}>
                    <NextLink
                      onClick={() =>
                        plausibleEvent('Suggestie: Klik', {
                          props: { documentId: suggestion?.documentId?.toString() },
                        })
                      }
                      href={`/reader/${suggestion.documentId}`}
                    >
                      <div className="flex h-20 min-w-[44px] gap-4 hover:text-accent md:h-16">
                        <img
                          src={suggestion.thumbnailUrl}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/issodefault.png';
                          }}
                          alt="Thumbnail"
                        />
                        <div>
                          <div className="text-xs">{suggestion.documentType}</div>
                          <div className="line-clamp-2 text-lg leading-5">{suggestion.title}</div>
                          <div className="text-xs">{suggestion.publicationDate}</div>
                        </div>
                      </div>
                    </NextLink>
                  </Menu.Item>
                </button>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    );
  }

  useEffect(() => {
    const urlQuery = router.query.query;
    if (urlQuery) {
      setQuery(urlQuery);
    }
  }, [router.query.query]);

  function renderSearchForm() {
    const handleClear = () => {
      setQuery('');
      setShowModal(false);
    };
    return (
      <form
        role="search"
        onSubmit={redirect && handleSubmit(handleSearch)}
        className={classNames(baseStyle, form.style)}
      >
        <input
          type="search"
          id="query"
          name="query"
          value={query}
          placeholder={input.placeholder}
          className={input.style}
          onInput={handleChange}
          onKeyDown={handleModal}
          {...register('query')}
          autoComplete="off"
        />

        {button ? (
          <button
            className="mr-2 rounded bg-primary-dark px-2 py-3"
            onClick={() => handleSubmit(handleSearch)}
            type="button"
          >
            <Icon iconName="search" color="text-white/90" size="md" overrideClass="px-2" />
          </button>
        ) : query ? (
          <button
            className="z-10 mr-2 rounded bg-primary-dark p-2 hover:bg-primary-dark/70"
            onClick={handleClear}
            type="button"
          >
            <Icon iconName="x" color="text-white/90" size="md" overrideClass="px-2.5" />
          </button>
        ) : (
          <button
            className="mr-2 rounded bg-primary-dark p-2 hover:bg-primary-dark/70"
            onClick={handleSearch}
            type="button"
          >
            <Icon iconName="search" color="text-white/90" size="md" overrideClass="px-2" />
          </button>
        )}
      </form>
    );
  }

  return (
    <>
      {showSuggestions ? (
        <Menu as="div" className="inline-block w-full text-left">
          {showModal && (
            <div
              className="fixed inset-0"
              role="presentation"
              onClick={() => setShowModal(false)}
            />
          )}
          {renderSearchForm()}
          <div className="md:ml-6">{renderSuggestions()}</div>
        </Menu>
      ) : (
        renderSearchForm()
      )}
    </>
  );
}
