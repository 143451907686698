import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';
import { serverSideApi } from './serverSideApi';

export async function performClientSearch(body, index_type) {
  try {
    return await clientApi(`/search/query?type=${index_type}`, 'POST', null, JSON.stringify(body));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function performServerSearch(req, res, body, index_type) {
  try {
    return await serverSideApi(
      req,
      res,
      `/search/query?type=${index_type}`,
      'POST',
      null,
      JSON.stringify(body),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}
