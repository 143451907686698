import {
  buildFacetOptions,
  buildFilterOptions,
  buildHighlightOptions,
  buildPaginationOptions,
  buildReturnFieldOptions,
  buildSearchQueryOptions,
  buildSearchTermOptions,
  buildSortOptions,
  buildSuggestOptions,
  removeSearchOptions,
} from './searchOptions';

/**
 * Builds the search options object from the passed in option parts
 */
function buildSearchOptions(
  searchQueryOptions,
  highlightOptions,
  facetOptions,
  sortOptions,
  returnFieldsOptions,
  paginationOptions,
  suggestOptions,
  options,
) {
  const defaultOptions = {
    ...paginationOptions,
    ...sortOptions,
    query: {
      ...searchQueryOptions,
    },
    aggs: {
      ...facetOptions,
    },
    ...returnFieldsOptions,
    ...highlightOptions,
    ...suggestOptions,
  };
  const mergedOptions = {
    ...defaultOptions,
    options,
  };
  return mergedOptions;
}

/**
 * Builds search request config for Elasticsearch
 * @param {Object} props
 * @param {String} props.searchTerm - The term to search for within search term fields
 * @param {Array<{field: String, boost: String}>} props.searchTermFields - The fields to apply the search term
 * @param {Array<String>} props.highlightTermFields - The fields to apply the highlight matching
 * @param {Array<String>} props.returnFields - The fields which are returned
 * @param {Array<{field: String, size: String}>} props.facets - The fields included in the search aggregation
 * @param {Array<{field: String, value: String}>} props.filters - The fields inluded in the search to narrow the results
 * @param {Array<{field: String, order: String}>} props.sorts - The fields inluded in the search to narrow the results
 * @param {String} props.from - The index point used to return results from the search
 * @param {String} props.size - The max index point used to limit the returned results
 * @param {Array.<String>} props.removeOptions - Array of strings used to remove options if passed in (e.g passing in 'sort' will remove the sort option)
 * @param {Object} props.options - One off options obejct which can be appended to the end of the search options object
 */
function buildSearchConfig(props) {
  const {
    index_type,
    searchTerm,
    searchTermFields,
    highlightTermFields,
    returnFields,
    facets,
    filters,
    sorts,
    from,
    size,
    removeOptions,
    suggest,
    options,
    match,
  } = props;

  const searchTermOptions = buildSearchTermOptions(searchTerm, searchTermFields, match);
  const filterOptions = buildFilterOptions(filters);
  const searchQueryOptions = buildSearchQueryOptions(filterOptions, searchTermOptions);

  const returnFieldsOptions = buildReturnFieldOptions(returnFields);
  const facetOptions = buildFacetOptions(facets);
  const sortOptions = buildSortOptions(sorts);
  const highlightOptions = buildHighlightOptions(highlightTermFields, index_type);
  const paginationOptions = buildPaginationOptions(from, size);
  const suggestOptions = buildSuggestOptions(suggest, searchTerm);

  const searchConfig = buildSearchOptions(
    searchQueryOptions,
    highlightOptions,
    facetOptions,
    sortOptions,
    returnFieldsOptions,
    paginationOptions,
    suggestOptions,
    options,
  );

  if (removeOptions?.length > 0) {
    removeSearchOptions(searchConfig, removeOptions);
  }

  return searchConfig;
}

export { buildSearchConfig };
