import { classNames } from '@helpers/classNames';

const GREEN = 'bg-green-100 ring-2 ring-green-400/20 text-functional-completed';
const LIGHTGREY = 'bg-gray-200 ring-2 ring-gray-400/20 text-black/80';
const RED = 'bg-functional-error-light ring-2 ring-red-400/20 text-functional-error/60';
const YELLOW = 'bg-primary ring-2 ring-primary text-primary-dark';

const badgeData = {
  ACTIVE: {
    styles: GREEN,
    translatedText: 'Actief',
  },
  COMPLETE: {
    styles: GREEN,
    translatedText: 'Afgerond',
  },
  EXPIRED: {
    styles: LIGHTGREY,
    translatedText: 'Verlopen',
  },
  FAILED: {
    styles: RED,
    translatedText: 'Mislukt',
  },
  INACTIVE: {
    styles: RED,
    translatedText: 'Inactief',
  },
  PENDING: {
    styles: YELLOW,
    translatedText: 'Uitgenodigd',
  },
  REVOKED: {
    styles: LIGHTGREY,
    translatedText: 'Ingetrokken',
  },
  UNVERIFIED: {
    styles: RED,
    translatedText: 'Niet geverifieerd',
  },
  VERIFIED: {
    styles: GREEN,
    translatedText: 'E-mail geverifieerd',
  },
  UNPAID: {
    styles: RED,
    translatedText: 'Nog te betalen',
  },
};

export default function StatusBadge({ badgeStatus, checked, overrideClass, icon }) {
  const translatedStatus = badgeData[badgeStatus]?.translatedText;
  const checkedStyle = checked ? 'border-2 border-green bg-green-300' : '';

  return (
    <div
      className={classNames(
        overrideClass
          ? overrideClass
          : 'w-20 rounded-md px-1 py-1.5 text-center text-xs ring-inset',
        badgeData[badgeStatus]?.styles,
        checkedStyle,
      )}
    >
      {translatedStatus ? translatedStatus : badgeStatus}
      {icon}
    </div>
  );
}
