import InviteTable from '@elements/Grid/InviteTable';
import DeleteOrganizationForm from '@forms/DeleteOrganizationForm';
import OrganizationAddressUpdateForm from '@forms/OrganizationAddressUpdateForm';
import OrdersView from 'feature/profile/fragment/Orders';
import OrganizationBusinessDetailsForm from 'feature/profile/fragment/OrganizationBusinessSettings';
import SubscriptionView from 'feature/profile/fragment/Subscriptions';

export const OrganizationData = [
  {
    label: 'Adressen',
    href: 'Adressen',
    content: (setSelectedIndex) => (
      <OrganizationAddressUpdateForm setSelectedIndex={setSelectedIndex} />
    ),
  },
  {
    label: 'Bedrijfsgegevens',
    href: 'bedrijfsgegevens',
    content: (setSelectedIndex) => (
      <OrganizationBusinessDetailsForm setSelectedIndex={setSelectedIndex} />
    ),
  },
  {
    label: 'Bestellingen',
    href: 'bestellingen',
    content: (setSelectedIndex) => <OrdersView setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Licentie',
    href: 'licentie',
    content: (setSelectedIndex) => <SubscriptionView setSelectedIndex={setSelectedIndex} />,
  },
  // TODO add back when the table work is complete
  // {
  //   label: 'Gekoppeld',
  //   href: 'Gekoppeld',
  //   content: (setSelectedIndex) => <LinkedProfiles setSelectedIndex={setSelectedIndex} />,
  // },
  {
    label: 'Profielen',
    href: 'Profielen',
    content: (setSelectedIndex) => <InviteTable setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Instellingen',
    href: 'Instellingen',
    content: (setSelectedIndex) => <DeleteOrganizationForm setSelectedIndex={setSelectedIndex} />,
  },
];
