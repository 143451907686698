import { userStore } from '@context/userStore';
import LinkButton from '@elements/buttons/LinkButton';
import { getCurrentProfileBundles } from '@providers/profile';
import {
  getOrganizationSubscriptionDetails,
  getProfileSubscriptionDetails,
} from '@providers/subscription';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import OrganizationSubscriptionCard from './Organization/OrganizationSubscriptionCard';
import ProfileLicenseShield from './Profile/ProfileLicenseShield';
import ProfileSubscriptionCard from './Profile/ProfileSubscriptionCard';

export default function SubscriptionView() {
  const { profileId, organizationId } = userStore();
  const [profileSubscription, setProfileSubscription] = useState({});
  const [organizationSubscription, setOrganizationSubscription] = useState({});
  const [profileLicenseDetails, setProfileLicenseDetails] = useState([]);

  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  const isOrganizationPage = router.pathname === '/organization';

  const fetchSubscriptionDetails = useCallback(async () => {
    setIsLoading(true);
    if (isOrganizationPage) {
      const response = await getOrganizationSubscriptionDetails(organizationId);
      if (response && response.status === 200) {
        setOrganizationSubscription(formatOrganizationSubscriptionData(response.data));
        setIsLoading(false);
      } else if (response && response.status === 404) {
        setIsLoading(false);
      }
    } else {
      const response = await getProfileSubscriptionDetails(profileId);
      if (response && response.status === 200) {
        setProfileSubscription(formatProfileSubscriptionData(response.data));
        setIsLoading(false);
      } else if (response && response.status === 404) {
        setIsLoading(false);
      }
    }
  }, []);

  const fetchProfileLicenseDetails = useCallback(async () => {
    if (!isOrganizationPage) {
      const response = await getCurrentProfileBundles(false);
      if (response && response.status === 200) {
        setProfileLicenseDetails(formatProfileLicenseDetailsData(response.data));
      }
    }
  }, [isOrganizationPage]);

  function formatProfileLicenseDetailsData(data = []) {
    let formattedData = [];
    if (Array.isArray(data) && data.length) {
      data.forEach((profileBundle) => {
        formattedData.push({
          bundleName: profileBundle.bundle.name,
          isActive: profileBundle.is_active,
          startDate: profileBundle.start_date,
          expirationDate: profileBundle.expiration_date,
        });
      });
    }
    return formattedData;
  }

  function formatOrganizationSubscriptionData(data = {}) {
    return {
      referenceNumber: data.subscription?.reference_number ?? '',
      totalAmount: data.subscription?.total_amount ?? 0,
      currentPeriodStart: data.subscription?.current_period_start ?? '',
      currentPeriodEnd: data.subscription?.current_period_end ?? '',
      invoiceUrl: data.subscription?.latest_invoice ?? '',
      nextInvoiceDate: data.subscription?.next_invoice_date ?? '',
      subscriptionStatus: data.subscription?.subscription_status ?? '',
      subscriptionMemberCount:
        Object.keys(data.subscription_member_count).length !== 0
          ? data.subscription_member_count
          : {},
      licenseType: data.subscription?.license_type ?? '',
      periodInterval: data.subscription?.period_interval ?? '',
      subscriptionHolder: data.subscription_holder ?? '',
      organizationType: data.subscription.type ?? '',
    };
  }

  function formatProfileSubscriptionData(data = {}) {
    return {
      referenceNumber: data.reference_number ?? '',
      totalAmount: data.total_amount ?? 0,
      currentPeriodStart: data.current_period_start ?? '',
      currentPeriodEnd: data.current_period_end ?? '',
      invoiceUrl: data.invoice_url ?? '',
      nextInvoiceDate: data.next_invoice_date ?? '',
      subscriptionItems:
        Array.isArray(data.subscription_items) && data.subscription_items.length
          ? data.subscription_items
          : [],
      licenseType: data.license_type ?? '',
    };
  }

  useEffect(() => {
    fetchSubscriptionDetails();
    fetchProfileLicenseDetails();
  }, [fetchSubscriptionDetails, fetchProfileLicenseDetails]);

  return (
    <div className="container mx-auto font-primary ">
      <h2 className="font-primary text-3xl">Licenties</h2>
      <p className="mb-8 mt-1 font-secondary">Bekijk hier de status van je licenties.</p>
      {!isOrganizationPage && <h3 className="mb-4 font-primary text-xl">Persoonlijk</h3>}
      <div className="mb-12 flex flex-col gap-8">
        <div className="mb-12 flex flex-col gap-8">
          {isOrganizationPage ? (
            isLoading ? (
              <Skeleton className="h-[100px]" />
            ) : (
              <OrganizationSubscriptionCard {...organizationSubscription} />
            )
          ) : profileSubscription?.totalAmount > 0 ||
            profileSubscription?.subscriptionItems?.length > 0 ? (
            isLoading ? (
              <Skeleton className="h-[100px]" />
            ) : (
              <ProfileSubscriptionCard {...profileSubscription} />
            )
          ) : (
            <div className="flex w-full flex-col rounded-lg bg-white px-8 py-4 shadow-lg">
              <div className="flex justify-between">
                <div className="pt-3">
                  <p>Je hebt momenteel geen actieve licentie.</p>
                </div>
                <div className="mt-1">
                  <LinkButton
                    label={'Bekijk licenties'}
                    href="/subscription?state=choose-subscription"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {!isOrganizationPage && (
          <div>
            {profileLicenseDetails.length > 0 && (
              <>
                <h3 className="mb-4 font-primary text-xl">Overig</h3>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-8 lg:grid-cols-3">
                  {profileLicenseDetails.map((license, index) => (
                    <ProfileLicenseShield key={index} {...license} />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
