import { classNames } from '@helpers/classNames';

const variants = {
  primary: {
    style: {
      bg: 'bg-primary',
    },
  },
  white: {
    style: {
      bg: 'bg-white',
    },
  },
  accent: {
    style: {
      bg: 'bg-accent',
    },
  },
  accentTwo: {
    style: {
      bg: 'bg-accent-3',
    },
  },
  none: {
    style: {
      bg: 'none',
    },
  },
};

const Container = ({ variant, children, overrideClass }) => {
  let style = '';
  if (variant) {
    style = variants[variant].style;
  } else {
    style = 'none';
  }

  return (
    <div className={classNames(style.bg)}>
      <div className={classNames('container mx-auto max-w-7xl px-4 xl:px-0', overrideClass)}>
        {children}
      </div>
    </div>
  );
};

export default Container;
