import { userStore } from '@context/userStore';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import AddProfileModal from '@forms/AddProfileToOrganization';
import EditLinkedProfileModal from '@forms/EditProfileLinkedToOrg';
import {
  getOrganizationBundle,
  getOrganizationInvites,
  removeProfilesFromOrganization,
} from '@providers/organization';
import { getOrganizationSubscriptionDetails } from '@providers/subscription';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatusBadge from '../badges/StatusBadge';

export default function InviteTable() {
  const [data, setInvitationData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const { organizationId } = userStore();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inviteButtonDisabled, setInviteButtonDisabled] = useState(false);
  const [organizationBundleExpirationDate, setOrganizationBundleExpirationDate] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editProfileData, setEditProfileData] = useState(null);

  const handleEditClick = (profile) => {
    setEditProfileData(profile);
    setShowEditModal(true);
  };

  const toggleRowSelection = (rowId) => {
    setSelectedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const toggleSelectAll = (selectAll) => {
    if (selectAll) {
      const newSelection = {};
      data.forEach((_, idx) => {
        newSelection[idx] = true;
      });
      setSelectedRows(newSelection);
    } else {
      setSelectedRows({});
    }
  };

  useEffect(() => {
    async function getInvitations() {
      const response = await getOrganizationInvites(organizationId);
      if (response?.status === 200) {
        const invitationData = response.data.map((item) => ({
          name: `${item.firstname || ''} ${item.lastname_prefix || ''} ${item.lastname || ''}`,
          email: item.email,
          status: item.invite_status,
          licenceStatus: item.license_status,
          licenceStartDate: item.start_date,
          licenceEndDate: item.expiration_date,
          userType: item.role,
          bundleId: item.bundle_id,
          profileId: item.profile_id,
        }));
        setInvitationData(invitationData);
      }

      const subscriptionResponse = await getOrganizationSubscriptionDetails(organizationId);
      if (subscriptionResponse?.status === 200) {
        const { active, pending, total } = subscriptionResponse.data.subscription_member_count;
        setInviteButtonDisabled(active + pending >= total);
      }

      const bundleResponse = await getOrganizationBundle(organizationId);
      if (bundleResponse?.status === 200) {
        setOrganizationBundleExpirationDate(bundleResponse.data.expiration_date);
      }
    }

    getInvitations();
  }, [organizationId, forceUpdate]);

  function licenceStatusTranslate(status) {
    switch (status) {
      case 'ACTIVE':
        return 'Actief';
      case 'NOT_ACTIVE':
        return 'Inactief';
      case 'PENDING':
        return 'In afwachting';
      default:
        return 'Onbekend';
    }
  }

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: () => {
          const allSelected = data.length > 0 && Object.keys(selectedRows).length === data.length;
          return (
            <input
              type="checkbox"
              checked={allSelected}
              onChange={(e) => toggleSelectAll(e.target.checked)}
            />
          );
        },
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={!!selectedRows[row.index]}
            onChange={() => toggleRowSelection(row.index)}
          />
        ),
      }),

      columnHelper.accessor('name', {
        header: 'Naam',
      }),
      columnHelper.accessor('email', {
        header: 'E-mail',
      }),
      columnHelper.accessor('status', {
        header: 'Uitnodiging',
        cell: ({ getValue }) => <StatusBadge badgeStatus={getValue()} />,
      }),
      columnHelper.accessor('licenceStatus', {
        header: 'Licentie',
        cell: ({ getValue }) => licenceStatusTranslate(getValue()),
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        cell: ({ row }) => <Button icon="pencil" onClick={() => handleEditClick(row.original)} />,
      }),
    ],
    [selectedRows],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
  });

  const onDeleteProfiles = async () => {
    const selectedProfiles = Object.keys(selectedRows)
      .filter((key) => selectedRows[key])
      .map((rowIndex) => ({
        profileId: data[rowIndex].profileId,
        email: data[rowIndex].email,
      }));

    const payload = {
      profile_ids: selectedProfiles.map((profile) => profile.profileId),
      emails: selectedProfiles.map((profile) => profile.email),
    };

    const response = await removeProfilesFromOrganization(organizationId, payload);

    if (response?.status === 204) {
      toast.success('Profiles removed successfully.');
      setForceUpdate((prev) => !prev);
      setShowConfirmation(false);
      setSelectedRows({});
    } else {
      toast.error('Failed to remove profiles.');
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Profielen</h2>
          <p className="mb-8 mt-1 font-secondary">
            Bekijk de status van alle gekoppelde profielen.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <Button
            label="Nodig profiel uit"
            onClick={() => setShowModalAdd(true)}
            disabled={inviteButtonDisabled}
            hoverText={
              inviteButtonDisabled ? 'Er zijn geen plaatsen beschikbaar. Upgrade de licentie' : ''
            }
          />
          <Button
            type="button"
            label="Profiel verwijderen"
            buttonType="danger"
            hoverText="Verwijder profiel uit organisatie"
            disabled={!Object.keys(selectedRows).some((key) => selectedRows[key])}
            onClick={async () => await onDeleteProfiles()}
          />
        </div>
      </div>

      <div className="mb-12 flex min-w-full flex-col rounded-lg bg-white shadow-lg">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead className="rounded-t-lg bg-primary-light text-left">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="border-b border-gray-300 px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <tr
                key={row.id}
                className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="border-b border-gray-300 px-6 py-4 text-sm text-gray-600"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className="flex items-center justify-between px-6 py-4">
                  <button
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    className="h-10 w-24 rounded-md border px-4 py-2 text-sm text-gray-500"
                  >
                    Vorige
                  </button>
                  <span className="text-sm text-gray-500">
                    Pagina {table.getState().pagination.pageIndex + 1} van {table.getPageCount()}
                  </span>
                  <button
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    className="h-10 w-24 rounded-md border px-4 py-2 text-sm text-gray-500"
                  >
                    Volgende
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <Modal
        showModal={showModalAdd}
        setShowModal={setShowModalAdd}
        modalTitle="Voeg profiel toe"
        modalContent={
          <AddProfileModal
            setShowModalAdd={setShowModalAdd}
            organizationBundleExpirationDate={organizationBundleExpirationDate}
          />
        }
      />

      <Modal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modalTitle="Edit Profile"
        modalContent={
          editProfileData && (
            <EditLinkedProfileModal
              email={editProfileData.email}
              licenceStatus={editProfileData.licenceStatus}
              licenceStartDate={editProfileData.licenceStartDate}
              licenceEndDate={editProfileData.licenceEndDate}
              setShowModal={setShowEditModal}
              userType={editProfileData.userType}
              bundleId={editProfileData.bundleId}
              profileId={editProfileData.profileId}
              organizationId={organizationId}
              organizationBundleExpirationDate={organizationBundleExpirationDate}
              setLicenceStatus={(newStatus) =>
                setInvitationData((prevData) =>
                  prevData.map((profile) =>
                    profile.profileId === editProfileData.profileId
                      ? { ...profile, licenceStatus: newStatus }
                      : profile,
                  ),
                )
              }
              onModalClose={() => setShowEditModal(false)}
            />
          )
        }
      />

      {showConfirmation && (
        <div className="fixed left-0 top-0 flex size-full items-center justify-center bg-gray-800/75">
          <div className="rounded-lg bg-white p-8">
            <h2 className="pb-2 font-primary font-bold">Profiel ontkoppelen van organisatie</h2>
            <p className="mb-4">
              Weet je zeker dat je de selectie wilt verwijderen?
              <br />
              Hierdoor hebben ze geen toegang meer tot de licentie.
              <br /> Het persoonlijke BouwZo profiel blijft wel bestaan.
            </p>
            <div className="flex justify-end gap-3 pt-2">
              <Button
                type="button"
                buttonType="danger"
                onClick={async () => await onDeleteProfiles()}
                label="Verwijder Profiel(en)"
              />
              <Button
                type="button"
                buttonType="secondary"
                onClick={() => setShowConfirmation(false)}
                label="Annuleren"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
