var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2XOujQ-Cio195pKu8gbOU"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/browser';

const NEXT_PUBLIC_SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;
const RELEASE = process.env.RELEASE || process.env.NEXT_PUBLIC_RELEASE;
const SENTRY_TRACE_RATE =
  process.env.SENTRY_TRACE_RATE || process.env.NEXT_PUBLIC_SENTRY_TRACE_RATE;
const SESSION_ERROR_SAMPLE_RATE =
  process.env.SESSION_ERROR_SAMPLE_RATE || process.env.SESSION_ERROR_SAMPLE_RATE;
const REPLAYS_ERROR_SAMPLE_RATE =
  process.env.REPLAYS_ERROR_SAMPLE_RATE || process.env.REPLAYS_ERROR_SAMPLE_RATE;

Sentry.init({
  dsn: NEXT_PUBLIC_SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
  tracesSampleRate: SENTRY_TRACE_RATE,
  replaysSessionSampleRate: SESSION_ERROR_SAMPLE_RATE,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: REPLAYS_ERROR_SAMPLE_RATE,

  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
