import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';

export default function InputField({
  label,
  name,
  placeholder,
  type,
  register,
  errors,
  message,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  value,
  isValid,
  customError,
  defaultValue,
  disabled,
  min,
  max,
  step,
  onInput,
  autoComplete,
  customWidth,
  overrideClass,
}) {
  const inputProps = {
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    value,
    defaultValue,
    disabled,
    min,
    max,
    step,
    onInput,
    autoComplete,
    name,
    overrideClass,
  };

  Object.keys(inputProps).forEach((key) => inputProps[key] === undefined && delete inputProps[key]);

  const fieldErrors = (errors && errors[name]) || customError;

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={classNames(
          'absolute -top-2 left-3 inline-block bg-white px-1 font-primary text-xs font-light tracking-wide',
          fieldErrors
            ? 'text-functional-error'
            : isValid
              ? 'focus-within:text-green-500'
              : 'focus-within:text-accent',
        )}
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        {...(register ? register(name) : {})}
        className={classNames(
          'block w-full rounded-md px-4 py-5 font-secondary text-sm tracking-wide text-primary-dark shadow-sm outline-none ring-1 ring-inset ring-[#E1E1E1] placeholder:text-primary-dark/30 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
          fieldErrors ? 'ring-red-500' : isValid ? 'ring-green-500' : 'focus:ring-accent',
          disabled ? 'bg-gray-100/20' : '',
          customWidth ? customWidth : '',
          overrideClass,
        )}
        placeholder={placeholder}
        {...inputProps}
      />
      {isValid && !fieldErrors && type !== 'number' && type !== 'date' && (
        <div
          className={classNames(
            'absolute inset-y-0 bottom-0 right-0 flex items-center pr-3 text-sm',
          )}
        >
          <Icon iconName="check" color="text-green-500" />
        </div>
      )}

      {fieldErrors && (
        <div className="mt-2 font-primary text-sm font-light tracking-wide text-functional-error">
          {message}
        </div>
      )}
    </div>
  );
}
