import { classNames } from '@helpers/classNames';
import Link from 'next/link';
import React from 'react';
import { linkStyle } from './nextLinkConstants';

const NextLink = React.forwardRef(
  ({ linkType = '', href = '', onClick, overrideClass = '', children }, ref) => {
    return (
      <Link
        onClick={onClick}
        href={href}
        // target={target}
        className={classNames(linkStyle[linkType], overrideClass)}
        ref={ref}
      >
        {children}
      </Link>
    );
  },
);

NextLink.displayName = 'NextLink';

export default NextLink;
